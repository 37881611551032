















































































































import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import CollectionService from "@/services/CollectionService";
import Collection from "@/models/Collection";
import Document from "@/models/Document";
import Video from "@/models/Video";
import VideoService from "@/services/VideoService";
import FilePickerComponent from "@/components/FilePickerComponent.vue";
import RouterTool from "@/services/tool/RouterTool";

@Component({components: {FilePickerComponent}})
export default class CollectionView extends Vue {
    @Ref() readonly form!: HTMLFormElement
    collection: Collection = new Collection()
    loading: boolean = false
    videos: Video[] = []
    image: File | null = null
    newImage: File | null = null
    video: Video | null = null
    tab: number = 0
    tabs: string[] = ["/images", "/videos"]


    created() {
        this.tab = RouterTool.configTabs(this, this.tabs)
        this.refresh()
    }

    refresh() {
        this.video = null
        CollectionService.getCollection(this, Number(this.$route.params.collectionId))
        VideoService.getVideos(this, this.videos);
    }

    @Watch("tab")
    watchTab() {
        RouterTool.watchTabs(this, "/collections/" + Number(this.$route.params.collectionId), this.tab, this.tabs)
    }

    updateCollection() {
        if (this.form.validate()) {
            getModule(DialogModule).showDialog(new Dialog(
                "Aviso",
                "¿Está seguro de actualizar la colección?",
                () => CollectionService.patchCollection(this, Number(this.$route.params.collectionId), this.collection, this.image!)
            ))
        }
    }

    addImage() {
        getModule(DialogModule).showDialog(new Dialog(
            "Aviso",
            "¿Está seguro de añadir la imagen?",
            () => CollectionService.postCollectionImage(this, Number(this.$route.params.collectionId), this.newImage!)
        ))
    }

    removeImage(image: Document) {
        getModule(DialogModule).showDialog(new Dialog(
            "Aviso",
            "¿Está seguro de eliminar la imagen?",
            () => CollectionService.deleteCollectionImage(this, Number(this.$route.params.collectionId), image.id!)
        ))
    }

    addVideo() {
        getModule(DialogModule).showDialog(new Dialog(
            "Aviso",
            "¿Está seguro de añadir el video?",
            () => CollectionService.patchCollectionVideoRelate(this, Number(this.$route.params.collectionId), this.video!.id!)
        ))
    }

    removeVideo(video: Video) {
        getModule(DialogModule).showDialog(new Dialog(
            "Aviso",
            "¿Está seguro de eliminar el video?",
            () => CollectionService.patchCollectionVideoUnrelate(this, Number(this.$route.params.collectionId), video.id!)
        ))
    }

    getYoutubeThumbnail(url: string): string {
        let regex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i;
        let match = url.match(regex)![1]
        return "https://img.youtube.com/vi/" + match + "/hqdefault.jpg"
    }
    getYoutubeEmbed(url: string): string {
        let regex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i;
        let match = url.match(regex)![1]
        return "http://www.youtube.com/embed/" + match
    }

    openUrl(url: string) {
        window.open(url, "_blank");
    }
}
